.main {
  background-color: black;
}
.fullheight {
  min-height: 100vh;
}
.bg {
  background-image: url("../assets/bg-main.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 660px;
  border-radius: 10px;
}
.poster {
  max-width: 100%;
}
.quantityBtn {
  background-color: transparent;
  border: none;
  color: white;
  width: 100px;
  height: 77px;

  background-image: url("../assets/qBtn.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  font-weight: 1000;
  font-size: 37px;
  margin: 7px;
}
.quantityBtn:hover,
.quantityBtn.active {
  background-image: url("../assets/qBtnHover.png");
}

.mintBtn {
  background-image: url("../assets/mintBg.png");
  background-color: transparent;
  border: none;
  color: white;
  width: 130px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: 1000;
  font-size: 13px;
}
