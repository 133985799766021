.tavern77 {
    width: 100%;
    height: auto;

    .video-container {
        position: relative;
        width: 100%;
      }

    #tavern77_inner-container {
        position:absolute;
        top:15%;
        left:35%;
        width: 15.2vw;
      }

    .infolink-container {
        .spritesheet-container {
            visibility: hidden;
        }
        :hover {
            .spritesheet-container {
                visibility: visible;
            }
        }
    }
}