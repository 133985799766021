  .home {
    width: 100%;
    margin:auto;

    .video-container {
      position: relative;
      width: 100%;
    }
  }
  
  #about-container {
    position:absolute;
    top:0%;
    left:35.3%;
    width: 27vw;
  }
  
  #tavern77-container {
    position:absolute;
    top:0%;
    left:80.25%;
    width: 15.2vw;
  }
  
  #team-container {
    position:absolute;
    top:30.1%;
    left:54.25%;
    width: 2.3vw;
  }
  
  #merc-container {
    position:absolute;
    top:12.3%;
    left:7.8%;
    width: 17.7vw;
  }
  
  #roadmap-container {
    position:absolute;
    top:13.1%;
    left:49.3%;
    width: 14vw;
  }
  
  #contracts-container {
    position:absolute;
    top:24.9%;
    left:81.6%;
    width: 7.5vw;
  }
  
  .infolink-container {
    .spritesheet-container {
      visibility: hidden;
    }
    :hover {
      .spritesheet-container {
         visibility: visible;
      }
    }
  }